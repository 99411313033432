'use client';
import { ActionErrorResponse, ActionResult } from '@/lib/action';
import { omit } from 'lodash';
import {
  createContext,
  useContext,
  forwardRef,
  ForwardedRef,
  useEffect,
  useId,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useFormState } from 'react-dom';

export interface FormProps<T>
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'action'> {
  action: (
    prevState: unknown,
    formData: FormData
  ) => Promise<ActionResult<T> | typeof INITIAL>;
  onSuccess?: (result: T) => void;
  onError?: (error: unknown) => void;
  title?: string;
  description?: string;
  args?: Record<string, string>;
}

const FormErrorContext = createContext<{
  serverError: ActionErrorResponse | null;
  clientFieldErrors: Record<string, string | undefined>;
  setClientFieldErrors: Dispatch<SetStateAction<Record<string, string>>>;
} | null>(null);

export const INITIAL = Symbol.for('initial');

function isErrorResponse(
  state: ActionResult<unknown>
): state is { error: ActionErrorResponse } {
  return typeof state === 'object' && state !== null && 'error' in state;
}

export default forwardRef(function Form<T>(
  {
    action,
    title,
    description,
    args,
    onSuccess,
    onError,
    children,
    ...otherProps
  }: FormProps<T>,
  ref: ForwardedRef<HTMLFormElement>
) {
  // Track client errors
  const [clientFieldErrors, setClientFieldErrors] = useState<
    Record<string, string>
  >({});

  // Add success/error callbacks to the action
  const actionWithCallbacks = useCallback(
    async (prevState: unknown, formData: FormData) => {
      if (Object.entries(clientFieldErrors).length > 0) {
        return;
      }
      const result = await action(prevState, formData);
      if (isErrorResponse(result)) {
        onError?.(result.error);
      } else if (typeof result !== 'symbol') {
        onSuccess?.(result);
      }
      return result;
    },
    [action, clientFieldErrors, onError, onSuccess]
  );

  // Track form state
  const [state, formAction] = useFormState(actionWithCallbacks, INITIAL);

  // Extract server errors from responses
  const [serverError, setServerError] = useState<
    ActionResult<any>['error'] | null
  >(null);

  useEffect(() => {
    if (state && typeof state === 'object' && 'error' in state) {
      setServerError(state.error);
    }
  }, [state]);

  const descriptionId = useId();

  return (
    <form
      {...otherProps}
      action={formAction}
      ref={ref}
      title={title}
      aria-describedby={descriptionId}
    >
      <FormErrorContext.Provider
        value={{ serverError, clientFieldErrors, setClientFieldErrors }}
      >
        <FormError />
        {args &&
          Object.entries(args).map(([key, value]) => (
            <input key={key} type="hidden" name={key} value={value} />
          ))}
        {title && (
          <>
            <div className="stack">
              <div className="font-medium">{title}</div>
              {description && (
                <div className="text-muted text-sm" id={descriptionId}>
                  {description}
                </div>
              )}
              <div className="h-2"></div>
              <hr />
            </div>
          </>
        )}
        {children}
      </FormErrorContext.Provider>
    </form>
  );
});

export function useFieldError(name: string) {
  const ctx = useContext(FormErrorContext);
  if (!ctx) throw new Error('useFieldErrors must be used within a Form');
  const { clientFieldErrors, setClientFieldErrors, serverError } = ctx;
  let error =
    clientFieldErrors[name] ??
    (serverError && 'fields' in serverError ? serverError.fields[name] : null);
  if (error && typeof error !== 'string' && 'message' in error) {
    error = error.message;
  }
  const setError = useCallback(
    (error: string | null) =>
      setClientFieldErrors((prev) =>
        error === null ? omit(prev, name) : { ...prev, [name]: error }
      ),
    [name, setClientFieldErrors]
  );
  return [error, setError] as const;
}

function FormError() {
  const ctx = useContext(FormErrorContext);
  if (!ctx) throw new Error('FormError must be used within a Form');
  const { serverError } = ctx;
  const formError = serverError && 'form' in serverError && serverError.form;
  if (!formError) return null;
  return (
    <div className="bg-red-500/80 rounded px-3 py-2 text-sm font-sans overflow-auto whitespace-pre my-2">
      <div className="font-medium">
        Whoops, something's not quite right there:
      </div>
      {formError.message.trim()}
    </div>
  );
}
